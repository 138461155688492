<template>
<a-layout-header style="background: #4DD493; padding: 0">
  <a-row>
    <a-col :span="16" class="span16">
      大美唐闸活动管理系统
    </a-col>
    <a-col :span="8"  class="span8" style="text-align: right;padding-right: 15px;">
        <a-icon type="team" />
        <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()"> {{name}}
            </a>
            <a-menu slot="overlay">
                <a-menu-item key="1">
                    <a rel="noopener noreferrer" href="javascript:void(0)">
                    <a-icon type="edit" />
                    <span @click="handleEditFile('file')">修改资料</span>
                    </a>
                </a-menu-item>
                <a-menu-item key="2">
                    <a rel="noopener noreferrer" href="javascript:void(0)" @click="handleLogout">
                    <a-icon type="diff" />
                    <span>退出</span>
                    </a>
                </a-menu-item>
            </a-menu>
        </a-dropdown>
    </a-col>
  </a-row>
  <a-modal
      title="个人资料修改"
      v-model="visible"
      class="modal"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
    >
      <a-form :form="form" :label-col="{ span: 6}" :wrapper-col="{ span: 18 }">
        <a-form-item label="登录账号">
            <a-input :value="name" :disabled="true" />
        </a-form-item>
        <a-form-item label="密码">
            <a-input autocomplete="off" v-decorator="['password', {rules: [{ required: true, message: '请输入密码!' }] }]" />
        </a-form-item>
        <a-form-item label="再次输入密码">
            <a-input autocomplete="off" v-decorator="['conform_password', {rules: [{ required: true, message: '请输入再次输入密码!' }] }]" />
        </a-form-item>
      </a-form>
    </a-modal>
</a-layout-header>
</template>

<script>
import { AdminModel } from "./../model/admin";
const adminModel = new AdminModel();
// import {mapGetters} from 'vuex'
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false,
      visible: false
    }
  },

  data() {
    return {
      visible: false,
      form: this.$form.createForm(this, {
        name: 'coordinated'
      }),
      title: '',
      name: localStorage.getItem('name')
    }
  },

  created() {
  },


  methods: {

    handleEditFile() {
        this.visible = true
    },

    handleLogout() {
      window.localStorage.clear()
      this.$router.push({
        path: '/login',
        query:{
          redirect: this.$route.path
        }
      })
    },

    handleOk(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(err, values)
          if(values.password.trim() != values.conform_password.trim()) {
            this.$notification.warning({
                message: "提示",
                description: "两次输入密码不一样！",
                duration: 2
            })
            return 
          }
          values.id = localStorage.getItem('id')
          values.name = this.name
          adminModel.updateAdmin(values).then(res => {
            if(res.code == '000000') {
              this.handleLogout()
            }
          }).catch(err => {
            console.log(err)
          })
        }
      });
    },

    handleCancel() {
      this.form.resetFields();
      this.visible = false
    }
  }
}
</script>

<style scoped>
.ant-dropdown-menu-item span {
  margin-left: 15px;
}

.anticon-team {
  margin-left: 5px;
  font-size: 18px;
}

.home {
  width: 80px;
  display: inline-block;
  text-align: center;
  margin-right: 30px;
  background: #f2f2f2;
  cursor: pointer;
}

a.u-home {
  background: #20a0ff;
  color: #fff !important;
}

.span16 {
    color: #fff;
    font-size: 24px;
    padding-left: 15px;
}

.span8 i,
.span8 a {
    color: #fff;
}

.modal /deep/ .ant-btn:nth-of-type(1):hover,
.modal /deep/ .ant-btn:nth-of-type(1):focus {
    color: #4DD493 !important;
    border-color: #4DD493 !important;
}

.modal /deep/ .ant-input:focus,
.modal /deep/ .ant-input:hover {
    border-color: #4DD493;
    box-shadow: 0 0 0 2px rgb(77 212 147 / 20%);
}
</style>
