<template>
  <div
    class="login">
    <a-row type="flex" justify="center">
      <a-col
        :span="6">
        <h2
          class="title">
          大美唐闸活动管理系统
        </h2>
        <a-form
          :form="form"
          @submit="handleSubmit">
          <a-form-item>
            <a-input
                size="large"
                autocomplete="off"
                v-decorator="['name', { rules: [{ required: true, message: '用户名不能为空!' }, {
                pattern: /^[a-zA-Z_.@]+$/,message: '用户名格式不正确！'}, {
                min: 4,message: '用户名至少4位！'}, {
                max: 32,message: '用户名最多不超过32位！'}] }]"
                placeholder="账号" />
          </a-form-item>
          <a-form-item>
            <a-input-password
                size="large"
                autocomplete="off"
                v-decorator="['password', { rules: [{ required: true, message: '密码不能为空!' }, {
                pattern: /^[a-zA-Z~@!#$%^&*().-_]+$/,message: '密码格式不正确！'}, {
                min: 6,message: '密码至少6位！'}, {
                max: 32,message: '密码最多不超过32位！'}] }]"
                placeholder="密码" />
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              size="large"
              html-type="submit">
              登录
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { LoginModel } from "../../model/login";
const loginModel = new LoginModel();
export default {
  data () {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
    }
  },

  mounted () {
    this.identifyCode = ''
  },

  methods: {
    handleSubmit (e) {
      e.preventDefault()
      // if (this.handerError) {
      //   console.log('错误')
      // }
      this.form.validateFields((err, values) => {
        if (!err) {
          loginModel.login(values).then(res => {
            if(res.code == '000000') {
                const {account, token} = res.data
                localStorage.setItem('name', account.name)
                localStorage.setItem('id', account.id)
                localStorage.setItem('token', token)
                this.$router.push('/manage')
            } else {
              this.$notification.error({
                message: "提示",
                description: res.message,
                duration: 2
              })
            }
          })
        }
      })
    },
  },
}

</script>

<style scoped>
.login {
  background: #fff;
}

.login .title {
  color: #4DD493;
  font-size: 22px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: normal;
  width: calc(100% - 70px);
}

.login /deep/ .ant-col-6 {
  margin-top: 15%;
}

.login /deep/ .ant-form {
  border-radius: 5px;
  padding: 45px 18px 25px;
  width: calc(100% - 70px);
  border: 1px solid#4DD493;
  box-shadow: 0 0 0 2px rgb(77 212 147 / 20%);
}

.login  /deep/ .ant-form-item {
  margin-bottom: 30px;
}

.login /deep/ .ant-input:focus,
.login /deep/ .ant-input:hover {
    border-color: #4DD493;
    box-shadow: 0 0 0 2px rgb(77 212 147 / 20%);
}

.login /deep/ .ant-btn {
    width: 100%;
    border-color: #4DD493;
    background-color: #4DD493;
}
</style>
