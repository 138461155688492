const menuList = [
    {
      title: '项目管理',
      key: '/manage',
      icon: 'tag'
    },

    {
        title: '资讯管理',
        key: '/info',
        icon: 'audit'
    },

    {
        title: '居民留言',
        key: '/message',
        icon: 'user'
    },
    
  ];
  export default menuList;