import {HTTP} from '../utils/axios'

class AdminModel extends HTTP {
  /*登录 */
  updateAdmin(value) {
      return this.request({
          url: '/api/v1/admin',
          method: "PATCH",
          data: value
      })
  }
}

export {AdminModel}