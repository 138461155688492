<template>
    <a-layout>
      <a-layout-header> <Header/> </a-layout-header>
      <a-layout>
        <a-layout-sider
        :trigger="null"
        :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0}"
        >
        <a-menu
            theme="dark"
            mode="inline"
            :defaultSelectedKeys="[routh]"
            :selectedKeys="[routh]"
            :defaultOpenKeys="['/' + $route.path.split('/')[1]]"
        >
            <template v-for="(item) in menuList">
            <a-menu-item v-if="!item.children" :key="item.key" @click="menu(item.key)">
                <a-icon :type="item.icon" />
                <span>{{item.title}}</span>
            </a-menu-item>
            <a-sub-menu v-else :key="item.key">
                <span slot="title">
                <a-icon :type="item.icon" />
                <span>{{item.title}}</span>
                </span>
                <template v-for="(item) in item.children">
                <a-menu-item :key="item.key" @click="menu(item.key)">{{item.title}}</a-menu-item>
                </template>
            </a-sub-menu>
            </template>
        </a-menu>
        </a-layout-sider>
        <a-layout-content><router-view></router-view></a-layout-content>
      </a-layout>
    </a-layout>
</template>

<script>
import Header from './../../components/BasicHeader.vue'
import menuList from "./menu";
export default {
  data() {
    return {
      menuList: menuList,
      routh: ''
    };
  },

  computed: {
    flag() {
      return this.$route.path != '/home' ? true : false
    }
  },

  created() {
    this.getRouth()
  },

  methods: {

    menu(s) {
      this.$router.push(s);
    },

    getRouth() {
      if (Object.keys(this.$route.params).length > 0) {
        this.routh = this.$route.path.substr(
          0,
          this.$route.path.length - this.$route.params.id.length - 1
        );
      } else {
        this.routh = this.$route.path;
      }
    }
  },

  watch: {
    $route(to) {
      if(to.path == '/home') {
        this.routh = ''
      } else {
        this.getRouth()
      }
      
    }
  },

  components: {
    Header,
  }
};
</script>

<style lang="scss">
.ant-layout {
    background: #fff !important;
}

.ant-layout-header {
    padding: 0 0 !important;
}

.ant-layout-content {
    margin-left: 200px;
}

.ant-layout-sider {
    background: #fff !important;
    box-shadow: 0 0 3px 5px #afa0a0;
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    color: #333 !important;
    background: #fff !important;
}

.ant-menu li {
    color: #333 !important;
}

.ant-menu li i {
    color: #4dd493 !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, 
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected  {
    background-color: #4dd493 !important;
    i {
        color: #fff !important;
    }
}
</style>
