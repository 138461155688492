import Vue from 'vue'
import router from './router/index'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import './assets/css/common.scss'
import App from './App.vue'


// Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(Antd)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
