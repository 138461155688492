import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/pages/login/Login'
import Manage from '@/pages/manage/Manage'
import Layout from '@/pages/layouts/Layout.vue'


Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/login',
      name: '登录页',
      component: Login,
    },
    {
        path: '/',
        redirect: '/manage',
        name: '项目管理',
        component: Layout,
        meta: {
          isLogin: true
        },
        children: [
            {
                path: '/manage',
                name: '',
                component: Manage,
            },
            {
              path: '/manage/:id',
              name: '',
              component: () => import('@/pages/manage/Detail'),
            }
        ]
    },
    {
      path: '/',
      redirect: '/info',
      name: '资讯管理',
      component: Layout,
      meta: {
        isLogin: true
      },
      children: [
          {
              path: '/info',
              name: '',
              component: () => import('@/pages/info/Info'),
          },
          {
            path: '/info/:id',
            name: '',
            component: () => import('@/pages/info/Detail'),
          }
      ]
    },
    {
      path: '/',
      redirect: '/message',
      name: '资讯管理',
      component: Layout,
      meta: {
        isLogin: true
      },
      children: [
          {
              path: '/message',
              name: '',
              component: () => import('@/pages/message/Message'),
          },
      ]
    },
    {
      path: '*',
      component: () => import('@/pages/errorPage/404'),
    },
  ],
})

router.beforeEach((to,from,next)=>{
  if(to.matched.some(res=>res.meta.isLogin)){//判断是否需要登录
      if (localStorage.getItem('token')) {
          next();
      }else{
          next({
              path:"/login",
              query:{
                  redirect:to.fullPath
              }
          });
      }

  }else{
    next()
  }

  /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
  if(to.path == "/login"){
    if(localStorage.getItem('token')){
      next({
        path: '/login'
      });
    }else {
      next();
    }
  }
});

export default router;
